/**
 *  Links 
*/

a.link--undercover,
a.link--block {
  color: inherit !important;
  text-decoration: inherit !important;
  cursor: pointer !important;
}

a.link--phone {
  text-decoration: none;
  color: $body-color;
  font-size: 1.3125rem;
}

a.link--promotions {

  font-size: 0.9375rem;
  padding-top: 1em;
  padding-left: 1em;
  margin-left: 10px;
  text-decoration: none;
  color: $brand-orange !important;

  &:hover {
    text-decoration: underline;
  }

  &:before {
    content: "\f0da";
    color: $brand-orange;
    font-family: FontAwesome;
    display: inline-block;
    margin-left: -1em;
    width: 1em;
  }

}

/* End of file : ./_links.scss */
