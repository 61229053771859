/**
 *  Modals specific styles
*/

.modal-backdrop.show {
  opacity: 0.8;
}

.modal-close {
  margin-top: 10px;
  margin-right: 10px;
  padding: 0; // reset bootstrap button
  background-color: transparent;
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 3px;
  border: 0;
}

#userModal {
  .modal-header {
    border: 0;
    border-radius: 0;
    background-color: $indigo;
  }

  .modal-content {
    border: 0;
    border-radius: 0;
  }

  .modal-footer {
    border-radius: 0;
  }
}

/* End of file : ./_modals.scss */
