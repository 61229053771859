/**
 *  Forms 
*/

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: $light;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  background-color: $primary;
}

/* End of file : ./_forms.scss */
