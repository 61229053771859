/**
 *  Prepare variables to set custom Bootstrap defaults
*/

@import './_variables.scss';
@import './_colors.scss';
@import './_typography.scss';
@import './_layout.scss';

/**
 *  Import Bootstrap default styles
*/

@import '../../node_modules/bootstrap/scss/bootstrap.scss';

/**
 *  Apply overrides to default Bootstrap styles
*/

@import './_links.scss';
@import './_buttons.scss';
@import './_forms.scss';

/**
 *  Import specific styles for application componentsw
*/

@import './_global.scss';
@import './_header.scss';
@import './_footer.scss';
@import './_modals.scss';
@import './_waves.scss';
@import './_nested_categories.scss';

/**
 *  Import specific styles for application pages
*/

@import './_homepage.scss';
@import './_produits.scss';
@import './_commande.scss';
@import './_message.scss';
