/**
 *  Footer 
*/

.page--commande {

  .quantity-setting {

    .btn:focus,
    .btn:active {
      outline: none;
      box-shadow: none;
    }

    .btn:active {
      color: $primary;
    }
  }

  .product-quantity {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 300;

    input {
      text-align: center;
      display: inline-block;
      width: 30px;
      height: calc(1.5em + .75rem + 2px);
      padding: .375rem .5px;
      font-size: .8125rem;
      font-weight: 300;
      line-height: 1.5;
      color: $body-color;
      background-color: $white;
      background-clip: padding-box;
      border: 1px solid $light;
      border-radius: .125rem;
    }
  }

  .freeshipping-yes {
    .alert-yes {
      display: block;
    }

    .alert-no {
      display: none;
    }
  }

  .freeshipping-no {
    .alert-yes {
      display: none;
    }

    .alert-no {
      display: block;
    }
  }
}

/* End of file : ./_footer.scss */
