/**
 *  Typography Globals
*/


$font-family-sans-serif: 'Roboto',
sans-serif;
$font-family-base: $font-family-sans-serif;

$font-size-base: 1rem;

$h1-font-size: $font-size-base * 2.25; // 36px
$h2-font-size: $font-size-base * 1.875; // 30px
$h3-font-size: $font-size-base * 1.5; // 24px
$h4-font-size: $font-size-base * 1.3125; // 21px
$h5-font-size: $font-size-base * 1.125; // 18 px
$h6-font-size: $font-size-base; // 16 px

$headings-font-weight: 700; // default 500
$normal-font-weight: 300;

h1,
.h1 {
  font-size: $h1-font-size;
}

h2,
.h2 {
  font-size: $h2-font-size;
}

h3,
.h3 {
  font-size: $h3-font-size;
}

h4,
.h4 {
  font-size: $h4-font-size;
}

h5,
.h5 {
  font-size: $h5-font-size;
}

h6,
.h6 {
  font-size: $h6-font-size;
}

h1 span.font-weight--normal,
h2 span.font-weight--normal,
h3 span.font-weight--normal,
h4 span.font-weight--normal,
h5 span.font-weight--normal,
h6 span.font-weight--normal {
  font-weight: $normal-font-weight;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-18 {
  font-size: 18px;
}

.font-thin {
  font-weight: 100 !important;
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-bold {
  font-weight: 700 !important;
}

del {
  text-decoration-line: line-through;
}
