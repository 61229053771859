/**
 *  Message 
*/

.message {
  ul {
    list-style: none;
  }
}

/* End of file : ./_message.scss */
