/**
 *  Header
*/

.header {

  .brand {
    height: 100px;
  }

  .navigation {

    .btn {
      display: inline-block;
      text-decoration: none;
      height: 70px;
      border-radius: 0;


      &.menu-trigger {
        color: $body-color;
        text-decoration: none;

        &:after {
          display: none;
        }
      }

      &.btn-search {
        color: $body-color;
      }
    }
  }
}




/* End of file : ./_header.scss */
