/**
 *  Waves
*/

.wave-bottom {

  background: $light;

  &:after {
    content: "";
    background-image: url(/images/style/wave-bottom.svg);
    background-color: #FFF;
    background-position: center top -1px;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    padding-bottom: 5%;
    width: 100%;
  }
}

.wave-top {

  background: $light;

  &:before {
    content: "";
    background-image: url(/images/style/wave-top.svg);
    background-color: #FFF;
    background-position: center bottom -1px;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    padding-top: 5%;
    width: 100%;
  }
}

/* End of file : ./_waves.scss */
