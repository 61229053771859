/**
 * Override Default bootstrap colors
*/

// Bootstrap defaults listed for reference
// $blue: #007bff !default;
// $indigo: #6610f2 !default;
// $purple: #6f42c1 !default;
// $pink: #e83e8c !default;
// $red: #dc3545 !default;
// $orange: #fd7e14 !default;
// $yellow: #ffc107 !default;
// $green: #28a745 !default;
// $teal: #20c997 !default;
// $cyan: #17a2b8 !default;


// Club Nestlé Pro brand colors
$brand-apricot: #F95B44;
$brand-blue: #1E78E5;
$brand-cyan: #2590CC;
$brand-cyan: #45ACE5;
$brand-grey-dark: #333333;
$brand-grey-medium: #ADB0B2;
$brand-grey-light: #F1F1F1;
$brand-green: #5CB85C;
$brand-orange: #F73013;
$brand-red: #B71D06;
$brand-yellow: #FDC42E;

// Override Default bootstrap colors
$blue: $brand-blue;
$cyan: $brand-cyan;
$green: $brand-green;
$orange: $brand-apricot;
$red: $brand-red;
$yellow: $brand-yellow;

$light: $brand-grey-light;
$primary: $orange;

$body-color: $brand-grey-dark;
$text-muted: $brand-grey-medium;

// Custom color
$highlight: lighten($yellow, 50%);

/* End of file : ./_colors.scss */
