/**
 *  Global 
*/

.cursor-pointer {
  cursor: pointer;

  * {
    cursor: pointer;
  }
}


.bg-hightlight {
  background-color: $highlight;
}


/* End of file : ./_global.scss */
