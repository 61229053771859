/**
 *  Nested Categories
*/

.nested_categories {

  font-size: 0.9375rem;
  padding-top: 1em;
  padding-left: 0;

  li {
    list-style: none;
    padding-left: 1em;
    margin-left: 10px;

    color: $yellow;

    ul {
      padding: 0;
      margin-left: -1em;
      /* same as padding-left set on li */
    }

    &:before {
      content: "•";
      /* FontAwesome Unicode */
      font-family: FontAwesome;
      display: inline-block;
      margin-left: -1em;
      /* same as padding-left set on li */
      width: 1em;
      /* same as padding-left set on li */
    }

    &.has-sublist::before {
      content: "\f0da";
      /* FontAwesome Unicode */
      font-family: FontAwesome;
      display: inline-block;
      margin-left: -1em;
      /* same as padding-left set on li */
      width: 1em;
      /* same as padding-left set on li */
    }
  }

  a {
    color: $body-color;
    display: inline-block;
    padding: 2px 5px;
  }

}

/* End of file : ./_nested_categories.scss */
