/**
 *  Layout 
*/

// Breakpoints

$media-sm: 576px;
$media-md: 768px;
$media-lg: 992px;
$media-xl: 1200px;

// Bootstrap overrides

$container-max-widths: (sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px // Should'nt we limit that to 980px ?
);

.container-fluid.has--max-width {
  max-width: map-get($container-max-widths, xl);
}


// Flex class  missing in bootstrap
.grow-1 {
  flex-grow: 1;
}

// Sticky footer

html,
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.page {
  flex: 1;
}

// Super margins

.my-10,
.mt-10 {
  margin-top: 100px;
}

.my-10,
.mb-10 {
  margin-bottom: 100px;
}

// Super padding

.py-10,
.pt-10 {
  padding-top: 100px;
}

.py-10,
.pb-10 {
  padding-bottom: 100px;
}

/* End of file : ./_layout.scss */
