/**
 *  Buttons 
*/

.btn.btn-xlarge {
  font-size: 1.3125rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.btn.btn-cta {
  padding: .875rem 1.5rem;
}

.btn.btn-outline-primary.btn-increment,
.btn.btn-outline-primary.btn-decrement {
  border-color: #ced4da;
  color: $body-color;

  &:hover,
  &:active {
    border-color: $primary;
    color: white;
  }
}


/* End of file : ./_buttons.scss */
