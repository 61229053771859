/**
 *  Footer 
*/

.footer {

  a:link,
  a:visited {
    color: $body-color;
  }

  a:hover,
  a:active {
    color: $primary;
  }

  ul {
    line-height: 2;
  }
}

/* End of file : ./_footer.scss */
