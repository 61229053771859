/**
 *  Homepage 
*/

.page--homepage {

  .hero {
    width: 100vw;
    height: 58vh;
    max-width: 100%;
    background-image: url('/images/homepage/hero_1200x800.jpg');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;

    .hero-content {
      border-radius: 0 100px 0 0;
      background: rgba($light, 0.8);
    }
  }

  .lead {

    font-size: 2.25rem;

    @media (max-width: $media-lg) {
      font-size: 1.875rem;
    }

    // @media (max-width: $media-md) {
    //   font-size: 1.5rem;
    // }
  }

  .teaser {

    h4 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1.125rem;
      line-height: 1.5;
      ;
    }
  }

  .brands {

    $brand-width: 60px;
    $brand-height: 45px;
    $brand-sprite-size: 300px 225px;
    // $brand-sprite-margin: 10px;
    $brand-sprite: url('/images/global/sprite-marques.png');

    .brand {
      display: inline-block;
      background-image: $brand-sprite;
      height: $brand-height;
      width: $brand-width;
      background-size: $brand-sprite-size;
      margin: 5px;

      span {
        display: none;
      }
    }

    .brand.brand--caro {
      background-position: ($brand-width - ($brand-width * 1)) ($brand-height - ($brand-height * 1));
    }

    .brand.brand--crunch {
      background-position: ($brand-width - ($brand-width * 1)) ($brand-height - ($brand-height * 2));
    }

    .brand.brand--cereales {
      background-position: ($brand-width - ($brand-width * 1)) ($brand-height - ($brand-height * 3));
    }

    .brand.brand--fido {
      background-position: ($brand-width - ($brand-width * 1)) ($brand-height - ($brand-height * 4));
    }

    .brand.brand--felix {
      background-position: ($brand-width - ($brand-width * 1)) ($brand-height - ($brand-height * 5));
    }

    .brand.brand--friskies {
      background-position: ($brand-width - ($brand-width * 2)) ($brand-height - ($brand-height * 1));
    }

    .brand.brand--guigoz {
      background-position: ($brand-width - ($brand-width * 2)) ($brand-height - ($brand-height * 2));
    }

    .brand.brand--kitkat {
      background-position: ($brand-width - ($brand-width * 2)) ($brand-height - ($brand-height * 3));
    }

    .brand.brand--kohler {
      background-position: ($brand-width - ($brand-width * 2)) ($brand-height - ($brand-height * 4));
    }

    .brand.brand--lion {
      background-position: ($brand-width - ($brand-width * 2)) ($brand-height - ($brand-height * 5));
    }

    .brand.brand--lcs {
      background-position: ($brand-width - ($brand-width * 3)) ($brand-height - ($brand-height * 1));
    }

    .brand.brand--bebe {
      background-position: ($brand-width - ($brand-width * 3)) ($brand-height - ($brand-height * 2));
    }

    .brand.brand--dessert {
      background-position: ($brand-width - ($brand-width * 3)) ($brand-height - ($brand-height * 3));
    }

    .brand.brand--nido {
      background-position: ($brand-width - ($brand-width * 3)) ($brand-height - ($brand-height * 4));
    }

    .brand.brand--nidal {
      background-position: ($brand-width - ($brand-width * 3)) ($brand-height - ($brand-height * 5));
    }

    .brand.brand--maggi {
      background-position: ($brand-width - ($brand-width * 4)) ($brand-height - ($brand-height * 1));
    }

    .brand.brand--nescafe {
      background-position: ($brand-width - ($brand-width * 4)) ($brand-height - ($brand-height * 2));
    }

    .brand.brand--ndg {
      background-position: ($brand-width - ($brand-width * 4)) ($brand-height - ($brand-height * 3));
    }

    .brand.brand--nesquik {
      background-position: ($brand-width - ($brand-width * 4)) ($brand-height - ($brand-height * 4));
    }

    .brand.brand--nestlé {
      background-position: ($brand-width - ($brand-width * 4)) ($brand-height - ($brand-height * 5));
    }

    .brand.brand--nuts {
      background-position: ($brand-width - ($brand-width * 5)) ($brand-height - ($brand-height * 1));
    }

    .brand.brand--purina {
      background-position: ($brand-width - ($brand-width * 5)) ($brand-height - ($brand-height * 2));
    }

    .brand.brand--ricore {
      background-position: ($brand-width - ($brand-width * 5)) ($brand-height - ($brand-height * 3));
    }

    .brand.brand--smarties {
      background-position: ($brand-width - ($brand-width * 5)) ($brand-height - ($brand-height * 4));
    }

    .brand.brand--tonimalt {
      background-position: ($brand-width - ($brand-width * 5)) ($brand-height - ($brand-height * 5));
    }

  }

}

/* End of file : ./_homepage.scss */
